
.HourPreview {
	position: relative;
	border: 1px solid #000;
	background-color: white;
}
.HourPreview h3 {
	text-align: center;
	position: absolute;
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	top: 0;
	line-height: 1;
	mix-blend-mode: difference;
	margin: 0;
	top: 50%;
	transform: translateY(-50%);
	/*text-shadow: 0 0 1px #fff;*/
}
.HourPreview .Minutes {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
}
.HourPreview .Minutes span {
	display: block;
	width: 100%;
	height: 0;
	padding-top: 178%;
}
.HourPreview .Minutes span.On {
	background-color: #ffffff;
}

.HourPreview.RandomColor_1 {
	border-color: #8c76ff;
}
.HourPreview.RandomColor_1 h3 {
	color: #8c76ff;
	color: #738900;
}
.HourPreview.RandomColor_1 .Minutes span.On {
	background-color: #8c76ff;
}
.HourPreview.RandomColor_2 {
	border-color: #ff3372;
}
.HourPreview.RandomColor_2 h3 {
	color: #ff3372;
	color: #00cc8d;
	mix-blend-mode: exclusion;
}
.HourPreview.RandomColor_2 .Minutes span.On {
	background-color: #ff3372;
}
.HourPreview.RandomColor_3 {
	border-color: #00ab00;
}
.HourPreview.RandomColor_3 h3 {
	color: #00ab00;
	color: #ff54ff;
	mix-blend-mode: exclusion;
}
.HourPreview.RandomColor_3 .Minutes span.On {
	background-color: #00ab00;
}
.HourPreview.RandomColor_4 {
	border-color: #133b33;
}
.HourPreview.RandomColor_4 h3 {
	color: #133b33;
	color: #ecc4cc;
}
.HourPreview.RandomColor_4 .Minutes span.On {
	background-color: #133b33;
}
.HourPreview.RandomColor_5 {
	border-color: #ffa100;
}
.HourPreview.RandomColor_5 h3 {
	color: #ffa100;
	color: #005eff;	
}
.HourPreview.RandomColor_5 .Minutes span.On {
	background-color: #ffa100;
}
.HourPreview.RandomColor_6 {
	border-color: #0000ff;
}
.HourPreview.RandomColor_6 h3 {
	color: #0000ff;
	color: #ffff00;
}
.HourPreview.RandomColor_6 .Minutes span.On {
	background-color: #0000ff;
}
.HourPreview.RandomColor_7 {
	border-color: #ff573f;
}
.HourPreview.RandomColor_7 h3 {
	color: #ff573f;
	color: #00a8c0;
	mix-blend-mode: exclusion;
}
.HourPreview.RandomColor_7 .Minutes span.On {
	background-color: #ff573f;
}
