
.Upload {
	color: #ddd;
	text-align: center;
	max-width: 320px;
	margin: 2rem auto;
}
.Upload.File {
	margin-top: 4rem;
}
.Upload label {
	text-align: right;
	font-size: 1rem;
	line-height: 1.2;
	color: #8c76ff;
	font-weight: 500;
}
.Upload #HourSelect {
	/*margin-right: 0.8em;*/
}
.Upload label[for="MinuteSelect"] {
	margin-left: 0.6em;
}
.Upload .SelectContainer {
	position: relative;
	display: inline-block;
}
.Upload select {
	color: #fff;
	font-size: 1em;
	margin-left: 0.4em;
	appearance: none;
	border: none;
	background: none;
	vertical-align: baseline;
	line-height: 1;
	border: 2px solid #8c76ff;
	border-radius: 0;
	color: #8c76ff;
	text-align: center;
	padding: 4px;
	font-weight: 600;
	padding-right: 1.3rem;
	font-family: 'IBM Plex Sans',sans-serif;
}
.Upload .SelectArrow {
	position: absolute;
	right: 0.4rem;
	height: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
	fill: #8c76ff;
	pointer-events: none;
}
.Upload input[type="file"] {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
}
.Upload label.FileLabel {
	color: #ff573f;
	font-size: 0.86rem;
	cursor: pointer;
	font-weight: 500;
}
.Upload label.FileLabel span {
	display: inline-block;
	text-align: left;
	width: 40%;
	height: 1rem;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: baseline;
	position: relative;
	top: 2px;
}
.Upload label.FileLabel:before {
	content: "Choose file";
	border: 2px solid #ff573f;
	font-size: 1rem;
	padding: 0.2rem 0.5rem;
	margin-right: 0.5rem;
	font-style: italic;
	display: inline-block;
	width: 40%;
	box-sizing: border-box;
	position: relative;
	text-align: center;
}
.Upload label.FileLabel.FileChosen:before {
	background-color: #ff573f;
	color: #fff;
}
.Terms {
	color: #ffa100;
	margin: 1rem auto;
	max-width: 300px;
	font-size: 0.86rem;
}
.Terms input {
	appearance: none;
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
}
.Terms input + label:before {
	content: " ";
	position: absolute;
	left: 3px;
	top: 4px;
	width: 8px;
	height: 8px;
	padding: 0;
	background: none;
	border: 2px solid #ffa100;
	border-radius: 0;
	display: block;
	box-sizing: content-box;
	margin-right: 0.5em;
}
.Terms input:checked + label:before {
	background-color: #ffa100;
}

.Terms label {
	display: inline-block;
	padding-left: 24px;
	position: relative;
}
.UploadButton {
	appearance: none;
	display: block;
	position: relative;
	margin: 2rem auto;
	padding: 0.2rem 1rem;
	border: 2px solid #133b33;
	appearance: none;
	background:none;
	color: #133b33;
	background-color: #ffffff;
	font-size: 1em;
	/*line-height: 1.5;*/
	font-style: italic;
	font-weight: 500;
	font-family: 'IBM Plex Sans',sans-serif;
}
.UploadButton:disabled {
	border-color: #a1b1ad;
	color: #a1b1ad;
	/*background-color: #ffffff;*/
}
.UploadButton .ProgressBar {
	position: absolute;
	top:0;
	bottom: 0;
	/*background-color: #ecc2cc;*/
	background-color: #133b33;
	left: 0;
	width: 0%;
	mix-blend-mode: darken;
}