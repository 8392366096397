@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,500i,600&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}
html {
	font-size: 18px;
}

code {
}

.Hour {
	padding: 0 12px;
}
.Hour .SubHeader {
	display: flex;
	align-items: baseline;
	max-width: 320px;
	margin: 0 auto;
}
.Hour .SubHeader h2 {
	flex-grow: 1;
	font-weight: 600;
	color: #ff3372;
	font-size: 1.6rem;
	margin-top: 0;
	margin-bottom: 30px;
	line-height: 0;
}
.Hour .SubHeader a {
	text-decoration: none;
	padding: 0 0.5em;
	font-weight: 600;
	color: #ff573f;
	font-size: 0.9rem;
}
.Hour .SubHeader a svg {
	display: inline-block;
	height: 0.8rem;
	position: relative;
	top: 1px;
	fill: #ff573f;
}
.Hour .Minutes {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-gap: 2px;
	margin-bottom: 1em;
	font-weight: 400;
}
.Hour .Minutes > div {
	width: 100%;
	height: 0;
	padding-top: 178%;
	position: relative;
}
.Hour .Minutes > div > div {
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid #000;
	color: #000;
	line-height: 1.1;
	font-size: 0.9rem;
}
.Hour .Minutes > div > div > a {
	text-decoration: none;
	flex: 1 1;
	font-size: 80%;
}
.Hour .Minutes .RandomColor_1 div {
	border-color: #8c76ff;
}
.Hour .Minutes .RandomColor_1 div a {
	color: #8c76ff;
}
.Hour .Minutes .RandomColor_2 div {
	border-color: #ff3372;
}
.Hour .Minutes .RandomColor_2 div a {
	color: #ff3372;
}
.Hour .Minutes .RandomColor_3 div {
	border-color: #00ab00;
}
.Hour .Minutes .RandomColor_3 div a {
	color: #00ab00;
}
.Hour .Minutes .RandomColor_4 div {
	border-color: #133b33;
}
.Hour .Minutes .RandomColor_4 div a {
	color: #133b33;
}
.Hour .Minutes .RandomColor_5 div {
	border-color: #ffa100;
}
.Hour .Minutes .RandomColor_5 div a {
	color: #ffa100;
}
.Hour .Minutes .RandomColor_6 div {
	border-color: #0000ff;
}
.Hour .Minutes .RandomColor_6 div a {
	color: #0000ff;
}
.Hour .Minutes .RandomColor_7 div {
	border-color: #ff573f;
}
.Hour .Minutes .RandomColor_7 div a {
	color: #ff573f;
}
.Hour .Minutes .HasScreen div {
	background-color: #000;
	border: none;
}
.Hour .Minutes img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}
.Hour .LargeImage {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 1);
	display: flex;
	align-items: center;
}
.Hour .LargeImage img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	display: block;
}

.HourPreview {
	position: relative;
	border: 1px solid #000;
	background-color: white;
}
.HourPreview h3 {
	text-align: center;
	position: absolute;
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	top: 0;
	line-height: 1;
	mix-blend-mode: difference;
	margin: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	/*text-shadow: 0 0 1px #fff;*/
}
.HourPreview .Minutes {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
}
.HourPreview .Minutes span {
	display: block;
	width: 100%;
	height: 0;
	padding-top: 178%;
}
.HourPreview .Minutes span.On {
	background-color: #ffffff;
}

.HourPreview.RandomColor_1 {
	border-color: #8c76ff;
}
.HourPreview.RandomColor_1 h3 {
	color: #8c76ff;
	color: #738900;
}
.HourPreview.RandomColor_1 .Minutes span.On {
	background-color: #8c76ff;
}
.HourPreview.RandomColor_2 {
	border-color: #ff3372;
}
.HourPreview.RandomColor_2 h3 {
	color: #ff3372;
	color: #00cc8d;
	mix-blend-mode: exclusion;
}
.HourPreview.RandomColor_2 .Minutes span.On {
	background-color: #ff3372;
}
.HourPreview.RandomColor_3 {
	border-color: #00ab00;
}
.HourPreview.RandomColor_3 h3 {
	color: #00ab00;
	color: #ff54ff;
	mix-blend-mode: exclusion;
}
.HourPreview.RandomColor_3 .Minutes span.On {
	background-color: #00ab00;
}
.HourPreview.RandomColor_4 {
	border-color: #133b33;
}
.HourPreview.RandomColor_4 h3 {
	color: #133b33;
	color: #ecc4cc;
}
.HourPreview.RandomColor_4 .Minutes span.On {
	background-color: #133b33;
}
.HourPreview.RandomColor_5 {
	border-color: #ffa100;
}
.HourPreview.RandomColor_5 h3 {
	color: #ffa100;
	color: #005eff;	
}
.HourPreview.RandomColor_5 .Minutes span.On {
	background-color: #ffa100;
}
.HourPreview.RandomColor_6 {
	border-color: #0000ff;
}
.HourPreview.RandomColor_6 h3 {
	color: #0000ff;
	color: #ffff00;
}
.HourPreview.RandomColor_6 .Minutes span.On {
	background-color: #0000ff;
}
.HourPreview.RandomColor_7 {
	border-color: #ff573f;
}
.HourPreview.RandomColor_7 h3 {
	color: #ff573f;
	color: #00a8c0;
	mix-blend-mode: exclusion;
}
.HourPreview.RandomColor_7 .Minutes span.On {
	background-color: #ff573f;
}

.Day {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 4px;
	margin-bottom: 1em;
	padding: 0 12px;
}


.Upload {
	color: #ddd;
	text-align: center;
	max-width: 320px;
	margin: 2rem auto;
}
.Upload.File {
	margin-top: 4rem;
}
.Upload label {
	text-align: right;
	font-size: 1rem;
	line-height: 1.2;
	color: #8c76ff;
	font-weight: 500;
}
.Upload #HourSelect {
	/*margin-right: 0.8em;*/
}
.Upload label[for="MinuteSelect"] {
	margin-left: 0.6em;
}
.Upload .SelectContainer {
	position: relative;
	display: inline-block;
}
.Upload select {
	color: #fff;
	font-size: 1em;
	margin-left: 0.4em;
	-webkit-appearance: none;
	        appearance: none;
	border: none;
	background: none;
	vertical-align: baseline;
	line-height: 1;
	border: 2px solid #8c76ff;
	border-radius: 0;
	color: #8c76ff;
	text-align: center;
	padding: 4px;
	font-weight: 600;
	padding-right: 1.3rem;
	font-family: 'IBM Plex Sans',sans-serif;
}
.Upload .SelectArrow {
	position: absolute;
	right: 0.4rem;
	height: 0.5rem;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	fill: #8c76ff;
	pointer-events: none;
}
.Upload input[type="file"] {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
}
.Upload label.FileLabel {
	color: #ff573f;
	font-size: 0.86rem;
	cursor: pointer;
	font-weight: 500;
}
.Upload label.FileLabel span {
	display: inline-block;
	text-align: left;
	width: 40%;
	height: 1rem;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: baseline;
	position: relative;
	top: 2px;
}
.Upload label.FileLabel:before {
	content: "Choose file";
	border: 2px solid #ff573f;
	font-size: 1rem;
	padding: 0.2rem 0.5rem;
	margin-right: 0.5rem;
	font-style: italic;
	display: inline-block;
	width: 40%;
	box-sizing: border-box;
	position: relative;
	text-align: center;
}
.Upload label.FileLabel.FileChosen:before {
	background-color: #ff573f;
	color: #fff;
}
.Terms {
	color: #ffa100;
	margin: 1rem auto;
	max-width: 300px;
	font-size: 0.86rem;
}
.Terms input {
	-webkit-appearance: none;
	        appearance: none;
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
}
.Terms input + label:before {
	content: " ";
	position: absolute;
	left: 3px;
	top: 4px;
	width: 8px;
	height: 8px;
	padding: 0;
	background: none;
	border: 2px solid #ffa100;
	border-radius: 0;
	display: block;
	box-sizing: content-box;
	margin-right: 0.5em;
}
.Terms input:checked + label:before {
	background-color: #ffa100;
}

.Terms label {
	display: inline-block;
	padding-left: 24px;
	position: relative;
}
.UploadButton {
	-webkit-appearance: none;
	        appearance: none;
	display: block;
	position: relative;
	margin: 2rem auto;
	padding: 0.2rem 1rem;
	border: 2px solid #133b33;
	appearance: none;
	background:none;
	color: #133b33;
	background-color: #ffffff;
	font-size: 1em;
	/*line-height: 1.5;*/
	font-style: italic;
	font-weight: 500;
	font-family: 'IBM Plex Sans',sans-serif;
}
.UploadButton:disabled {
	border-color: #a1b1ad;
	color: #a1b1ad;
	/*background-color: #ffffff;*/
}
.UploadButton .ProgressBar {
	position: absolute;
	top:0;
	bottom: 0;
	/*background-color: #ecc2cc;*/
	background-color: #133b33;
	left: 0;
	width: 0%;
	mix-blend-mode: darken;
}
.Clock {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
}

.Clock .Closer {
	display: block;
	position: fixed;
	top: 1rem;
	right: 1rem;
	/*border: 1px solid white;*/
	mix-blend-mode: difference;
	width: 1rem;
	height: 1rem;
	/*border-radius: 50%;*/
	text-align: center;
	line-height: 0.9rem;
	opacity: 0;
	transition: opacity 0.1s linear;
}
.Clock .Closer.Show {
	display: block;
	opacity: 1;
}
.Clock .Closer a {
	text-decoration: none;
}
.Clock .Closer svg rect {
	fill: white;
}

.Clock .NoImageFoundInfo {
	display: none;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-80%);
	        transform: translateY(-80%);
}
.Clock .NoImageFoundInfo h2 {
	font-size: 1.4rem;
	margin: 0;
}
.Clock .NoImageFoundInfo p {
	font-size: 0.7rem;
	margin: 0;
}
.Clock .NoImageFoundInfo p a {
	text-decoration: none;
}
.Clock .ClockImage {
	object-fit: contain;
	width: 100%;
	height: 100%;
	object-position: center center;
}

.Clock.NoImageFound {
	background-color: white;
}
.Clock.NoImageFound .NoImageFoundInfo {
	display: block;
}
.Clock.NoImageFound .ClockImage {
	display: none;
}

.Clock .RandomColor_1 {
	color: #8c76ff;
}
.Clock .RandomColor_2 {
	color: #ff3372;
}
.Clock .RandomColor_3 {
	color: #00ab00;
}
.Clock .RandomColor_4 {
	color: #133b33;
}
.Clock .RandomColor_5 {
	color: #ffa100;
}
.Clock .RandomColor_6 {
	color: #0000ff;
}
.Clock .RandomColor_7 {
	color: #ff573f;
}

.App {
  color: #ccc;
  font-family: "IBM Plex Sans", sans-serif;
  max-width: 415px;
  margin: 1rem auto;
  position: relative;
}

@media screen and (min-width: 415px) {
  .App {
    border: 1px solid #0000ff;
    min-height: 800px;
  }
}

.App a {
  color: inherit;
}
.App a:active {
  color: inherit;
}
.App p {
  margin: 0.5em;
}

.App h2 {
  text-align: center;
}
.App-header h1 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: #0000ff;
  margin-bottom: 0;
  margin-top: 30px;
  letter-spacing: -0.025em;
}
.App-header p {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: #0000ff;
  margin: 0;
  line-height: 0.5;
  margin-bottom: 25px;
}
.App-header h1 a,
.App-header p a {
  text-decoration: none;
}
.App-header ul {
  padding: 0.5em 18px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style: none;
  font-style: italic;
  font-weight: 500;
  /*max-width: 300px;*/
  margin-bottom: 45px;
}
.App-header ul a {
  color: #b3e6b3;
  white-space: nowrap;
  font-size: 0.95rem;
}
@media screen and (max-width: 360px) {
  .App-header ul a {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 320px) {
  .App-header ul a {
    font-size: 0.8rem;
  }
}
.App-header ul a.active {
  color: #00ab00;
}
.App-header ul li {
  /*flex: 1;*/
  text-align: center;
}
.About {
  font-weight: 500;
  color: #ff573f;
  padding: 0 1.5em;
  margin: 0 auto;
  line-height: 1.5em;
}
.About a {
  color: #133b33;
  /*font-weight: 500;*/
  font-style: italic;
}
.About ol {
  -webkit-padding-start: 2em;
          padding-inline-start: 2em;
}
.About .FooterText {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 1em;
  font-size: 0.7rem;
  line-height: 1.2;
  font-weight: 100;
  color: black;
}
@media screen and (max-height: 710px) {
  .About .FooterText {
    text-align: center;
    position: relative;
    bottom: 0;
    margin-top: 2em;
  }
}
@media screen and (min-height: 800px) {
  .About .FooterText {
    bottom: auto;
    top: 750px;
  }
}
.App-link {
}

