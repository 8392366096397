.Hour {
	padding: 0 12px;
}
.Hour .SubHeader {
	display: flex;
	align-items: baseline;
	max-width: 320px;
	margin: 0 auto;
}
.Hour .SubHeader h2 {
	flex-grow: 1;
	font-weight: 600;
	color: #ff3372;
	font-size: 1.6rem;
	margin-top: 0;
	margin-bottom: 30px;
	line-height: 0;
}
.Hour .SubHeader a {
	text-decoration: none;
	padding: 0 0.5em;
	font-weight: 600;
	color: #ff573f;
	font-size: 0.9rem;
}
.Hour .SubHeader a svg {
	display: inline-block;
	height: 0.8rem;
	position: relative;
	top: 1px;
	fill: #ff573f;
}
.Hour .Minutes {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-gap: 2px;
	margin-bottom: 1em;
	font-weight: 400;
}
.Hour .Minutes > div {
	width: 100%;
	height: 0;
	padding-top: 178%;
	position: relative;
}
.Hour .Minutes > div > div {
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid #000;
	color: #000;
	line-height: 1.1;
	font-size: 0.9rem;
}
.Hour .Minutes > div > div > a {
	text-decoration: none;
	flex: 1;
	font-size: 80%;
}
.Hour .Minutes .RandomColor_1 div {
	border-color: #8c76ff;
}
.Hour .Minutes .RandomColor_1 div a {
	color: #8c76ff;
}
.Hour .Minutes .RandomColor_2 div {
	border-color: #ff3372;
}
.Hour .Minutes .RandomColor_2 div a {
	color: #ff3372;
}
.Hour .Minutes .RandomColor_3 div {
	border-color: #00ab00;
}
.Hour .Minutes .RandomColor_3 div a {
	color: #00ab00;
}
.Hour .Minutes .RandomColor_4 div {
	border-color: #133b33;
}
.Hour .Minutes .RandomColor_4 div a {
	color: #133b33;
}
.Hour .Minutes .RandomColor_5 div {
	border-color: #ffa100;
}
.Hour .Minutes .RandomColor_5 div a {
	color: #ffa100;
}
.Hour .Minutes .RandomColor_6 div {
	border-color: #0000ff;
}
.Hour .Minutes .RandomColor_6 div a {
	color: #0000ff;
}
.Hour .Minutes .RandomColor_7 div {
	border-color: #ff573f;
}
.Hour .Minutes .RandomColor_7 div a {
	color: #ff573f;
}
.Hour .Minutes .HasScreen div {
	background-color: #000;
	border: none;
}
.Hour .Minutes img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}
.Hour .LargeImage {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 1);
	display: flex;
	align-items: center;
}
.Hour .LargeImage img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	display: block;
}