.App {
  color: #ccc;
  font-family: "IBM Plex Sans", sans-serif;
  max-width: 415px;
  margin: 1rem auto;
  position: relative;
}

@media screen and (min-width: 415px) {
  .App {
    border: 1px solid #0000ff;
    min-height: 800px;
  }
}

.App a {
  color: inherit;
}
.App a:active {
  color: inherit;
}
.App p {
  margin: 0.5em;
}

.App h2 {
  text-align: center;
}
.App-header h1 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: #0000ff;
  margin-bottom: 0;
  margin-top: 30px;
  letter-spacing: -0.025em;
}
.App-header p {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: #0000ff;
  margin: 0;
  line-height: 0.5;
  margin-bottom: 25px;
}
.App-header h1 a,
.App-header p a {
  text-decoration: none;
}
.App-header ul {
  padding: 0.5em 18px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style: none;
  font-style: italic;
  font-weight: 500;
  /*max-width: 300px;*/
  margin-bottom: 45px;
}
.App-header ul a {
  color: #b3e6b3;
  white-space: nowrap;
  font-size: 0.95rem;
}
@media screen and (max-width: 360px) {
  .App-header ul a {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 320px) {
  .App-header ul a {
    font-size: 0.8rem;
  }
}
.App-header ul a.active {
  color: #00ab00;
}
.App-header ul li {
  /*flex: 1;*/
  text-align: center;
}
.About {
  font-weight: 500;
  color: #ff573f;
  padding: 0 1.5em;
  margin: 0 auto;
  line-height: 1.5em;
}
.About a {
  color: #133b33;
  /*font-weight: 500;*/
  font-style: italic;
}
.About ol {
  padding-inline-start: 2em;
}
.About .FooterText {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 1em;
  font-size: 0.7rem;
  line-height: 1.2;
  font-weight: 100;
  color: black;
}
@media screen and (max-height: 710px) {
  .About .FooterText {
    text-align: center;
    position: relative;
    bottom: 0;
    margin-top: 2em;
  }
}
@media screen and (min-height: 800px) {
  .About .FooterText {
    bottom: auto;
    top: 750px;
  }
}
.App-link {
}
