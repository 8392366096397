.Clock {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
}

.Clock .Closer {
	display: block;
	position: fixed;
	top: 1rem;
	right: 1rem;
	/*border: 1px solid white;*/
	mix-blend-mode: difference;
	width: 1rem;
	height: 1rem;
	/*border-radius: 50%;*/
	text-align: center;
	line-height: 0.9rem;
	opacity: 0;
	transition: opacity 0.1s linear;
}
.Clock .Closer.Show {
	display: block;
	opacity: 1;
}
.Clock .Closer a {
	text-decoration: none;
}
.Clock .Closer svg rect {
	fill: white;
}

.Clock .NoImageFoundInfo {
	display: none;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-80%);
}
.Clock .NoImageFoundInfo h2 {
	font-size: 1.4rem;
	margin: 0;
}
.Clock .NoImageFoundInfo p {
	font-size: 0.7rem;
	margin: 0;
}
.Clock .NoImageFoundInfo p a {
	text-decoration: none;
}
.Clock .ClockImage {
	object-fit: contain;
	width: 100%;
	height: 100%;
	object-position: center center;
}

.Clock.NoImageFound {
	background-color: white;
}
.Clock.NoImageFound .NoImageFoundInfo {
	display: block;
}
.Clock.NoImageFound .ClockImage {
	display: none;
}

.Clock .RandomColor_1 {
	color: #8c76ff;
}
.Clock .RandomColor_2 {
	color: #ff3372;
}
.Clock .RandomColor_3 {
	color: #00ab00;
}
.Clock .RandomColor_4 {
	color: #133b33;
}
.Clock .RandomColor_5 {
	color: #ffa100;
}
.Clock .RandomColor_6 {
	color: #0000ff;
}
.Clock .RandomColor_7 {
	color: #ff573f;
}
