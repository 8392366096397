@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,500i,600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-variant-numeric: tabular-nums;
}
html {
	font-size: 18px;
}

code {
}
